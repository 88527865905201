import { createFileRoute, Link } from "@tanstack/react-router";
import { ErrorDisplay } from "@/components/error.tsx";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";
import { cloneElement, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Button, Text } from "@/components/custom-components";
import { Divider } from "@/components/custom-components/Divider";
import { SidebarButton } from "@/components/templates/Sidebar";
import AdIcon from "@/assets/AdIcon.tsx";
import LanderIcon from "@/assets/LanderIcon.tsx";
import {
  LandingFeedAdCard,
  LandingFeedProps,
} from "@/components/templates/LandingAdCard";
import { toast } from "sonner";
import { ShareIcon } from "lucide-react";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/brands/$brandID/landing-pages")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const { sideBarOpen } = Route.useSearch();

  const { brandID } = Route.useParams();

  const [allData, setAllData] = useState<LandingFeedProps[] | undefined>(
    undefined,
  );

  const [, copyToClipboard] = useCopyToClipboard();

  const { data, fetchNextPage, isLoading, isError, isRefetching } =
    trpc.getBrandLanders.useInfiniteQuery(
      {
        brandId: brandID,
      },
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    );

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (!inView || !data || !allData || !allData.length) return;
    if (data.pages[data.pages.length - 1].results.length === 0) {
      return;
    }
    fetchNextPage();
  }, [inView, data, allData, fetchNextPage]);

  useEffect(() => {
    if (!data) return;

    setAllData(() => undefined);
    const records = [] as LandingFeedProps[];
    for (const page of data.pages) {
      records.push(...page.results);
    }
    setAllData(() => records);
  }, [data]);

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }

  return (
    <>
      <div className={""}>
        <div className={"bg-white sticky px-5 md:px-5 lg:pb-2 top-0 z-10"}>
          <div className={"pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <Text
                size={"lg"}
                weight={"semibold"}
                className={
                  "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                }
              >
                {data?.pages[0].brandName}
              </Text>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />

            <div className={"flex gap-5 items-center justify-between"}>
              <div className={"flex gap-5 items-center justify-between"}>
                <Link to={"/feeds/brands"}>
                  <Button className={"px-3 h-12 lg:h-auto"}>Back</Button>
                </Link>
                <div className={"flex gap-3 lg:gap-5 items-center"}>
                  <span className={"hidden lg:flex"}>Show:</span>
                  <div
                    className={
                      "flex gap-2 lg:gap-5 items-center overflow-x-auto w-[90vw] md:w-[70vw] lg:w-auto"
                    }
                  >
                    {[
                      {
                        name: "Templates",
                        link: `/feeds/brands/${brandID}`,
                        icon: <AdIcon />,
                      },
                      {
                        name: "Landers",
                        link: `/feeds/brands/${brandID}/landing-pages`,
                        icon: <LanderIcon />,
                      },
                    ].map((item) => {
                      const isActive = location.pathname === item.link;
                      const iconColor = isActive ? "white" : "black";
                      return (
                        <Link
                          to={item.link}
                          search={(d) => ({
                            ...d,
                            cacheBuster: Math.random(),
                            orderFilter: "Random",
                            sideBarOpen: false,
                          })}
                          className={
                            "flex gap-2.5 justify-start items-center h-12 lg:h-10 px-3 lg:px-5 py-4 border border-black rounded-md text-[0.875rem] font-normal"
                          }
                          activeProps={{
                            className:
                              "border-0 border-transparent rounded-lg bg-gradient-to-b from-[#A259FF] to-[#613599] text-white",
                          }}
                          activeOptions={{
                            exact: true,
                            includeSearch: false,
                          }}
                          key={item.name}
                        >
                          <span>
                            <span>
                              {cloneElement(item.icon, { color: iconColor })}
                            </span>
                          </span>
                          <Text
                            className={
                              "w-auto text-nowrap text-sm md:text-base"
                            }
                            weight={"medium"}
                            data-testid={item.name}
                          >
                            {item.name}
                          </Text>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className={"flex gap-2 items-center"}>
                <Button
                  onClick={() => {
                    copyToClipboard(
                      `${window.location.origin}/feeds/brands/${brandID}/landing-pages`,
                    );
                    toast("Copied!");
                  }}
                  className={"hidden md:flex px-2.5 h-12 lg:h-auto"}
                >
                  <span>Share</span>
                </Button>
                <span
                  onClick={() => {
                    copyToClipboard(
                      `${window.location.origin}/feeds/brands/${brandID}/landing-pages`,
                    );
                    toast("Copied!");
                  }}
                  className={
                    "flex md:hidden border rounded-md border-black p-2.5 cursor-pointer"
                  }
                >
                  <ShareIcon className={"w-[20px] h-[20px]"} />
                </span>
              </div>
            </div>

            <Divider className={"mt-[1.25rem] hidden md:flex"} />
          </div>
        </div>
        <div className="px-5">
          {isLoading || isRefetching ? (
            <div className="flex justify-center items-center w-full h-screen">
              <Loader />
            </div>
          ) : (
            <div>
              {data && allData && allData.length === 0 ? (
                <div className="flex flex-col justify-center items-center">
                  <p className="text-center w-4/5 lg:w-1/2 mb-5">
                    There are currently no landing page inspiration for this
                    brand.
                  </p>
                  <img src="/giphy.webp" width="480" height="270" alt="" />
                </div>
              ) : (
                <div
                  className={
                    "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2.5"
                  }
                >
                  {data &&
                    allData &&
                    allData.length > 0 &&
                    allData.map((card) => (
                      <LandingFeedAdCard key={card.landerId} adData={card} />
                    ))}
                  <div className={"relative"}>
                    <div
                      className={
                        "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
                      }
                      ref={scrollRef}
                    ></div>
                  </div>{" "}
                  <div className={"relative"}>
                    <div
                      className={
                        "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
                      }
                      ref={scrollRef}
                    ></div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default All;
