import "./App.css";
import type { AppRouter } from "../../server";
import { createTRPCReact } from "@trpc/react-query";
import { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpLink } from "@trpc/client";

import { RouterProvider, createRouter } from "@tanstack/react-router";
import { MemberstackProvider } from "@memberstack/react";
import "./lib/amplitude";
import memberstackDOM from "@memberstack/dom";

export const memberstack = memberstackDOM.init({
  publicKey: "pk_971c0de956b797603818",
});

// Import the generated route tree
import { routeTree } from "./routeTree.gen";

export const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const config = {
  publicKey: "pk_971c0de956b797603818",
};

export const trpc = createTRPCReact<AppRouter>();

export function App() {
  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpLink({
          url: import.meta.env.VITE_API_URL as string,
          fetch(url, options) {
            return fetch(url, {
              ...options,
              credentials: "include",
            });
          },
          // You can pass any HTTP headers you wish here
          async headers() {
            const authToken = localStorage.getItem("authToken");
            if (!authToken) {
              return {};
            }
            return {
              authorization: `Bearer ${authToken}`,
            };
          },
        }),
      ],
    })
  );

  return (
    <MemberstackProvider config={config}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </trpc.Provider>
    </MemberstackProvider>
  );
}
